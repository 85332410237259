<template>
  <KpiDivisiForm mode="Ubah" module="KPI"> </KpiDivisiForm>
</template>

<script>
import KpiDivisiForm from './form';

const KpiDivisiUpdate = {
  name: 'KpiDivisiUpdate',
  components: { KpiDivisiForm },
};

export default KpiDivisiUpdate;
</script>
